export const clientData = [
    {
        name: 'CD Entertainment',
        description: 'Local DJ',
        link: 'https://cdentertainment.co'
    },
    {
        name: 'Rosebud Quilting',
        description: 'Local Quilting Business',
        link: 'https://rosebudquilting.com'
    },
    {
        name: '44th Street Tattoo',
        description: 'Local Tattoo Shop',
        link: 'https://tattoos44thstreet.com'
    }
];
